import React, { Component } from "react";
import "../../src/index.css";
import styled from "styled-components";
import MuiWrapper from "../../src/MuiWrapper";
import AppConfig from "../../src/Config";
import NavbarFactory from "../../src/Components/Navbar";
import FooterFactory from "../../src/Components/Footer";
import { Helmet } from "react-helmet";

const Navbar = NavbarFactory(AppConfig.navbar);
const Footer = FooterFactory(AppConfig.footer);

class Layout extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this._onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._onScroll);
  }

  render() {
    let {
      location: { pathname }
    } = this.props;
    let passBreakpoint = !!this.passBreakpoint;
    let hasNavbar = false;
    let hasFooter = true;
    pathname = this._normalizePath(pathname);

    let withPaddingTop = AppConfig.navbar.coverContent ? false : false;
    if (
      pathname === "/" ||
      (pathname.length === 4 && pathname.match(/\/\w{2}\/$/))
    ) {
      hasFooter = false;
    } else if (pathname.slice(-4) === "/me/") {
      withPaddingTop = false;
      hasFooter = false;
    } else if (pathname.slice(-6) === "/cart/") {
      withPaddingTop = true;
    }

    return (
      <MuiWrapper>
        <Layout.Wrapper withPaddingTop={withPaddingTop}>
          <Helmet>
            <title>藍田產後護理</title>
            <meta name="description" content="藍田產後護理之家" />
            <link rel="shortcut icon" href="/favicon.ico" />
          </Helmet>

          {hasNavbar && (
            <Navbar passBreakpoint={passBreakpoint || withPaddingTop} />
          )}

          <Layout.Content>{this.props.children}</Layout.Content>

          {hasFooter && <Footer />}
        </Layout.Wrapper>
      </MuiWrapper>
    );
  }

  _normalizePath = path => {
    if (path.slice(0, 2) === "//") {
      path = path.slice(1);
    }

    if (path[path.length - 1] !== "/") {
      path += "/";
    }

    return path;
  };

  _onScroll = e => {
    const breakpoint = 600;
    let scrollTop = document.querySelector("html").scrollTop;

    if (scrollTop > breakpoint) {
      if (!this.passBreakpoint) {
        this.passBreakpoint = true;
        this.forceUpdate();
      }
    } else {
      if (this.passBreakpoint) {
        this.passBreakpoint = false;
        this.forceUpdate();
      }
    }
  };
}

Layout.Wrapper = styled.div`
  min-height: calc(100vh);
  background-color: white;
  padding-top: ${props =>
    props.withPaddingTop ? AppConfig.navbar.height : 0}px;
  display: flex;
  flex-direction: column;
`;

Layout.Content = styled.div`
  flex: 1;
  align-self: stretch;
`;

export default Layout;
