import React from 'react';
import styled from 'styled-components';
import Link from '../../../src/Components/Link';
import * as Icon from '../../../src/Components/Icon';

export default function NavbarFactory({
  coverContent=true,
  background='rgba(0,0,0,0)',
  altBackground='#0079ff',
  textColor='white',
  textShadow='2px 2px #ccc',
  height=60,
}={}) {
  class Navbar extends React.Component {
    render() {
      let {passBreakpoint} = this.props;

      return (
        <Style.Wrapper passBreakpoint={passBreakpoint}>
          <Style.Content>
            <Link to="/" className="site-title">
              藍田產後之家
            </Link>
  
            <div className="navs">
              <Link to="/me" className="nav-item">
                <Icon.Person color='white' />
              </Link>
            </div>
          </Style.Content>
        </Style.Wrapper>
      );
    }
  }
  
  const Style = {
    Wrapper: styled.div`
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      padding: 0px 20px;
      z-index:10;
      background: ${props => props.passBreakpoint ? altBackground : background};
      transition: background 200ms ease-in-out;
    `,
  
    Content: styled.div`
      margin: 0 auto;
      max-width: 1024px;
      height: ${height}px;
      display: flex;
      align-items: center;
    
      & > .site-title {
        color: ${textColor};
        text-shadow: ${textShadow};
        text-decoration: none;
        font-size: 30px;
      }
    
      & > .navs {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    
        & > .nav-item {
          margin-right: 15px;
          color: ${textColor};
          text-shadow: ${textShadow};
          text-decoration: none;
          display: flex;
          align-items: center;
        }

        & > .nav-item :last-child {
          margin-right: 0px;
        }
      }
    `
  }

  return Navbar;
}
