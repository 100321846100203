import React from 'react';
import { Provider } from 'react-redux';
import Layout from '../../src/Layout';
import { getStore } from '../../src/Store';
import ActionCreator from '../../src/ActionCreator';

async function dispatchRouteInitClientActions({ store, location }) {
  let { appActions } = ActionCreator(store.dispatch);

  if (!store.getState().user.data) {
    await appActions.autoLogin();
  }
}

class Page extends React.Component {
  render() {
    return (
      <Provider store={this.store}>
        <Layout key={this.props.pageContext.lang} {...this.props}>
          {this.props.children}
        </Layout>
      </Provider>
    );
  }

  UNSAFE_componentWillMount() {
    this.store = getStore();
    let appActions = ActionCreator(this.store.dispatch).appActions;
    appActions.setLang(this.props.pageContext.lang);
  }

  async componentDidMount() {
    await dispatchRouteInitClientActions({
      store: getStore(),
      location: this.props.location,
    });
  }
}

export function withPage(Comp) {
  class PageWrapper extends React.Component {
    render() {
      return (
        <Page {...this.props}>
          <Comp {...this.props}>{this.props.children}</Comp>
        </Page>
      );
    }
  }

  PageWrapper.displayName = 'Page-' + Comp.displayName;
  return PageWrapper;
}

export default Page;
