import CartServiceFactory from '../../src/Services/Cart';
import CatalogServiceFactory from '../../src/Services/Catalog';
import Config from '../../src/Domain/constants';
import { namedExportWithMemorizedOnce } from '../../src/Utils/FpUtil';
import MomCenterServiceFactory from './MomCenter'

console.log('mom-center plugin-service applied');

const Services = {
  cart: CartServiceFactory(Config.cart),
  [CatalogServiceFactory().name]: CatalogServiceFactory(Config.catalog),
  momCenter: MomCenterServiceFactory(),
};

function getReducers() {
  return Object.keys(Services).reduce((acc, serviceName) => {
    acc[serviceName] = Services[serviceName].reducer;
    return acc;
  }, {});
}

function getActionCreators(store) {
  return Object.keys(Services).reduce((acc, serviceName) => {
    acc[serviceName] = Services[serviceName].actionCreator(store);
    return acc;
  }, {});
}

function getSelectors(state) {
  return Object.keys(Services).reduce((acc, serviceName) => {
    let selectors = Services[serviceName].selectors(state);
    acc[serviceName] = namedExportWithMemorizedOnce(selectors);
    return acc;
  }, {});
}

export { getReducers, getActionCreators, getSelectors };
