import React, { Component } from 'react';
import styled from 'styled-components';
import LangSwitcher from '../../../src/Components/LangSwitcher';
import Link from '../../../src/Components/Link';

const FooterFactory = (config) => {
  class Footer extends Component {
    render() {
      let { extraStyle = '' } = this.props;

      return (
        <Wrapper extraStyle={extraStyle}>
          <Content main>
            <div className="company-name">產後之家</div>
            <LangSwitcher />
            <div className="copyright">
              Copyright © 2018 Revteltech Limited. All rights reserved.
          </div>
          </Content>

          <Content>
            <div className="menu">
              <div>
                <Link
                  to="/"
                  extraStyle={{
                    marginTop: 20,
                    textDecoration: 'none',
                    color: '#505050',
                  }}
                >
                  Home
              </Link>
              </div>
            </div>
          </Content>
        </Wrapper>
      );
    }
  }

  let Wrapper = styled.div`
    background-color: #f8f8f8;
    width: 100vw;
    padding: 40px 10px 80px 10px;
    color: #505050;
    display: flex;
    @media screen and (max-width: 500px) {
      flex-direction: column;
      align-items: center;
    }
    ${props => props.extraStyle};
  `;

  let Content = styled.div`
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    flex-grow: ${props => (props.main ? 1 : 0)};
    min-height: ${props => (props.main ? '150px' : '100px')};
    min-width: 200px;
  
    & > .company-name {
      color: #37c491;
      font-size: 34px;
    }
  
    & > .copyright {
      font-size: 14px;
    }
  
    & > .title {
      font-size: 15px;
      margin-bottom: 10px;
    }
  
    & > .menu {
      flex-grow: 1;
    }
  
    @media screen and (max-width: 500px) {
      margin-top: 20px;
      width: 100%;
    }
  `;
  return Footer;
}

export default FooterFactory;
