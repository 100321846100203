import React from 'react';
import styled from 'styled-components';
import {
  CircularProgress,
  RaisedButton,
  FlatButton,
  IconButton,
  DropDownMenu,
  MenuItem,
  RadioButton,
  Checkbox,
} from 'material-ui';

export const BgImage = styled.div`
  background-color: ${props => props.color || '#d0d0d0'};
  background-image: url(${props => props.image});
  background-size: ${props => props.size || 'cover'};
  background-repeat: ${props => props.repeat || 'no-repeat'};
  background-position: ${props => props.position || 'center'};
  width: 100%;
  height: 100%;
  ${props => props.extraCss};
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${props => props.extraCss};
`;

export const MaxWidth = styled.div`
  max-width: ${props => props.width}px;
  margin: ${props => props.margin || '0 auto'};
  ${props => props.extraCss};
`;

export const Row = styled.div`
  display: flex;
  align-items: ${props => props.align || 'stretch'};
  justify-content: ${props => props.justify || 'flex-start'};
  flex-wrap: ${props => (props.wrap ? 'wrap' : 'nowrap')};
  ${props => props.extraCss};
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align || 'stretch'};
  justify-content: ${props => props.justify || 'flex-start'};
  ${props => props.extraCss};
`;

export const BaseButton = styled.button`
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  ${props => props.extraStyle};
`;

export const Input = styled.input`
  padding: 5px;
  font-size: 16px;
  width: 100%;
  outline: none;
  border: 1px solid #ddd;

  &:focus {
    outline: 1px solid #06bcd5;
    box-shadow: ${props => (props.inset ? 'inset' : '')} 0 0 0px 2px
      rgba(6, 188, 213, 0.5);
    outline-offset: -1px;
  }

  ${props => props.extraCss};
`;

export const FixRatioImage = ({
  image,
  width = '100%',
  heightRatio = '56.25%',
  extraStyle,
}) => (
  //heightRatio: (9: 16 = 56.25%) (3 : 4 = 75%)
  <div style={{ width: width, ...extraStyle }}>
    <div style={{ position: 'relative', top: 0, paddingTop: heightRatio }}>
      <BgImage image={image} extraCss="position: absolute; top: 0px;" />
    </div>
  </div>
);

export const MuiSelect = props => {
  return (
    <DropDownMenu
      {...props}
      onChange={(event, index, value) => {
        // to fit in unstated form usage
        console.log(value);
        props.onChange({ ...event, target: { value: value } });
      }}
    >
      {props.children}
    </DropDownMenu>
  );
};

export const Spinner = CircularProgress;
export const Button = RaisedButton;
export { FlatButton, IconButton, MenuItem, RadioButton, Checkbox };
