import ApiProxy from "../../src/ApiProxy";

function _idFlatten(data) {
  let clone = { ...data };
  for (let k in clone) {
    let prop = clone[k];
    if (prop && typeof prop === "object" && prop.id) {
      clone[k] = prop.id;
    }
  }
  return clone;
}

export default function MomCenterService(config = {}) {
  const name = "momCenter";
  const initialState = {
    users: [],
    rooms: [],
    roomTypes: [],
    incubators: [],
    incubaorTypes: [],
    spareParts: [],
    topNavState: "rooms",
    momDashboardState: {
      selectedTab: "mother", // TODO: move the UiTab definition
      uiState: "normal", // TODO: move the UiState definiation
      selectedMother: null
    },
    babyDashboardState: {
      selectedTab: "babyInfo",
      uiState: "normal",
      selectedMother: null,
      selectedBaby: null
    }
  };
  const Actions = {
    SET_USER_LIST: name + ":SET_USER_LIST",
    SET_ROOM_LIST: name + ":SET_ROOM_LIST",
    SET_ROOM_TYPE_LIST: name + ":SET_ROOM_TYPE_LIST",
    SET_INCUBATOR_LIST: name + ":SET_INCUBATOR_LIST",
    SET_INCUBATOR_TYPE_LIST: name + ":SET_INCUBATOR_TYPE_LIST",
    SET_SPARE_PART_LIST: name + ":SET_SPARE_PART_LIST",
    SET_TOP_NAV_STATE: name + ":SET_TOP_NAV_STATE",
    SET_MOM_DASHBOARD_STATE: name + ":SET_MOM_DASHBOARD_STATE",
    SET_BABY_DASHBOARD_STATE: name + ":SET_BABY_DASHBOARD_STATE"
  };

  function reducer(state = initialState, { type, payload }) {
    switch (type) {
      case Actions.SET_USER_LIST:
        return {
          ...state,
          users: payload
        };

      case Actions.SET_TOP_NAV_STATE:
        return {
          ...state,
          topNavState: payload
        };

      case Actions.SET_MOM_DASHBOARD_STATE:
        return {
          ...state,
          momDashboardState: {
            ...state.momDashboardState,
            ...payload
          }
        };

      case Actions.SET_BABY_DASHBOARD_STATE:
        return {
          ...state,
          babyDashboardState: {
            ...state.babyDashboardState,
            ...payload
          }
        };

      case Actions.SET_ROOM_LIST:
        return {
          ...state,
          rooms: payload
        };

      case Actions.SET_ROOM_TYPE_LIST:
        return {
          ...state,
          roomTypes: payload
        };

      case Actions.SET_INCUBATOR_LIST:
        return {
          ...state,
          incubators: payload
        };

      case Actions.SET_INCUBATOR_TYPE_LIST:
        return {
          ...state,
          incubatorTypes: payload
        };

      case Actions.SET_SPARE_PART_LIST:
        return {
          ...state,
          spareParts: payload
        };

      default:
        return state;
    }
  }

  function actionCreator(store) {
    const dispatch = store.dispatch;

    return {
      momCheckout: ({ id, username, password }) => {
        return ApiProxy.get({
          secure: true,
          path: `/api/mom_checkout/?mother=${id}&username=${username}&password=${password}`
        });
      },

      fetchMomMealMenu: id => {
        return ApiProxy.get({
          secure: true,
          path: `/api/mom_meal_menu/?mother=${id}`
        });
      },

      createMomMealMenu: data => {
        return ApiProxy.post({
          secure: true,
          path: `/api/mom_meal_menu/`,
          data: {
            ...data
          }
        });
      },

      updateMomMealMenu: ({ id, ...data }) => {
        return ApiProxy.put({
          secure: true,
          path: `/api/mom_meal_menu/${id}`,
          data: {
            ...data
          }
        });
      },

      deleteMomMomMealMenu: id => {
        return ApiProxy.delete({
          secure: true,
          path: `/api/mom_meal_menu/${id}/`
        });
      },

      fetchMomCheckinoutRecord: id => {
        return ApiProxy.get({
          secure: true,
          path: `/api/mom_checkin_record/?mother=${id}`
        });
      },

      fetchMomsCheckinStatus: records => {
        const queryParams = JSON.stringify(records.map(r => r.mother.id));
        return ApiProxy.get({
          secure: true,
          path: `/api/mom_checkin_record/multiple/?mothers=${queryParams}`
        });
      },

      createMomCheckinoutRecord: data => {
        return ApiProxy.post({
          secure: true,
          path: `/api/mom_checkin_record/`,
          data: {
            ...data
          }
        });
      },

      fetchUserList: async () => {
        try {
          const payload = await ApiProxy.get({
            secure: true,
            path: `/api/user/`
          });

          dispatch({ type: Actions.SET_USER_LIST, payload });
        } catch (ex) {
          console.warn(ex);
        }
      },

      fetchMomCheckinEval: id => {
        return ApiProxy.get({
          secure: true,
          path: `/api/mom_checkedin_eval/?mother=${id}`
        });
      },

      createMomCheckinEval: data => {
        return ApiProxy.post({
          secure: true,
          path: `/api/mom_checkedin_eval/`,
          data: {
            ...data
          }
        });
      },

      updateMomCheckinEval: ({ id, ...data }) => {
        return ApiProxy.put({
          secure: true,
          path: `/api/mom_checkedin_eval/${id}`,
          data: {
            ...data
          }
        });
      },

      deleteMomCheckinEval: id => {
        return ApiProxy.delete({
          secure: true,
          path: `/api/mom_checkedin_eval/${id}`
        });
      },

      fetchMomBillingRecord: id => {
        return ApiProxy.get({
          secure: true,
          path: `/api/mom_billing_record/?mother=${id}`
        });
      },

      createMomBillingRecord: data => {
        console.log("data", data);
        return ApiProxy.post({
          secure: true,
          path: `/api/mom_billing_record/`,
          data: {
            ...data
          }
        });
      },

      updateMomBillingRecord: ({ id, ...data }) => {
        return ApiProxy.put({
          secure: true,
          path: `/api/mom_billing_record/${id}`,
          data: {
            ...data
          }
        });
      },

      deleteMomBillingRecord: id => {
        return ApiProxy.delete({
          secure: true,
          path: `/api/mom_billing_record/${id}`
        });
      },

      fetchMomBorrowRecord: id => {
        return ApiProxy.get({
          secure: true,
          path: `/api/mom_borrow_record/?mother=${id}`
        });
      },

      createMomBorrowRecord: data => {
        return ApiProxy.post({
          secure: true,
          path: `/api/mom_borrow_record/`,
          data: {
            ...data
          }
        });
      },

      updateMomBorrowRecord: ({ id, ...data }) => {
        return ApiProxy.put({
          secure: true,
          path: `/api/mom_borrow_record/${id}`,
          data: {
            ...data
          }
        });
      },

      deleteMomBorrowRecord: id => {
        return ApiProxy.delete({
          secure: true,
          path: `/api/mom_borrow_record/${id}/`
        });
      },

      fetchRepairmentRecord: (id = "") => {
        return ApiProxy.get({
          secure: true,
          path: `/api/room_repairment_record/?room=${id}`
        });
      },

      createRepairmentRecord: data => {
        return ApiProxy.post({
          secure: true,
          path: `/api/room_repairment_record/`,
          data: {
            ...data
          }
        });
      },

      updateRepairment: ({ id, ...data }) => {
        return ApiProxy.put({
          secure: true,
          path: `/api/room_repairment_record/${id}/`,
          data: {
            ..._idFlatten(data)
          }
        });
      },

      deleteRepairment: id => {
        return ApiProxy.delete({
          secure: true,
          path: `/api/room_repairment_record/${id}/`
        });
      },

      searchMomHealthRecord: ({ mother }) => {
        return ApiProxy.get({
          secure: true,
          path: `/api/mom_health_record/?mother=${mother}`
        });
      },
      creatMomHealthRecord: data => {
        return ApiProxy.post({
          secure: true,
          path: `/api/mom_health_record/`,
          data: data
        });
      },

      updateMomHealthRecord: ({ id, ...data }) => {
        return ApiProxy.put({
          secure: true,
          path: `/api/mom_health_record/${id}/`,
          data: {
            ..._idFlatten(data)
          }
        });
      },

      deleteMomHealthRecord: id => {
        return ApiProxy.delete({
          secure: true,
          path: `/api/mom_health_record/${id}/`
        });
      },

      searchMomCaringRecord: ({ mother }) => {
        return ApiProxy.get({
          secure: true,
          path: `/api/mom_caring_record/?mother=${mother}`
        });
      },
      creatMomCaringRecord: data => {
        return ApiProxy.post({
          secure: true,
          path: `/api/mom_caring_record/`,
          data: data
        });
      },

      updateMomCaringRecord: ({ id, ...data }) => {
        return ApiProxy.put({
          secure: true,
          path: `/api/mom_caring_record/${id}/`,
          data: {
            ..._idFlatten(data)
          }
        });
      },

      deleteMomCaringRecord: id => {
        return ApiProxy.delete({
          secure: true,
          path: `/api/mom_caring_record/${id}/`
        });
      },

      fetchBabyMomRoomingInRecord: id => {
        return ApiProxy.get({
          secure: true,
          path: `/api/baby_mom_rooming_in_record/?baby=${id}`
        });
      },

      createBabyMomRoomingInRecord: data => {
        return ApiProxy.post({
          secure: true,
          path: `/api/baby_mom_rooming_in_record/`,
          data: {
            ...data
          }
        });
      },

      updateBabyMomRoomingInRecord: ({ id, ...data }) => {
        return ApiProxy.put({
          secure: true,
          path: `/api/baby_mom_rooming_in_record/${id}`,
          data: {
            ...data
          }
        });
      },

      deletBabyMomRoomingInRecord: id => {
        return ApiProxy.delete({
          secure: true,
          path: `/api/baby_mom_rooming_in_record/${id}`
        });
      },

      fetchBabyHealthRecord: id => {
        return ApiProxy.get({
          secure: true,
          path: `/api/baby_health_record/?baby=${id}`
        });
      },

      createBabyHealthRecord: data => {
        return ApiProxy.post({
          secure: true,
          path: `/api/baby_health_record/`,
          data: {
            ...data
          }
        });
      },

      updateBabyHealthRecord: ({ id, ...data }) => {
        return ApiProxy.put({
          secure: true,
          path: `/api/baby_health_record/${id}`,
          data: {
            ...data
          }
        });
      },

      deleteBabyHealthRecord: id => {
        return ApiProxy.delete({
          secure: true,
          path: `/api/baby_health_record/${id}/`
        });
      },

      searchBabyLivingRecordsByDate: ({ from_date, duration }) => {
        return ApiProxy.get({
          secure: true,
          path: `/api/incubator_arrangement/?from_date=${from_date}&duration=${duration}`
        });
      },

      updateBabyLivingRecord: ({ id, ...data }) => {
        return ApiProxy.put({
          secure: true,
          path: `/api/incubator_arrangement/${id}/`,
          data: {
            ..._idFlatten(data)
          }
        });
      },

      createBabyLivingRecord: data => {
        return ApiProxy.post({
          secure: true,
          path: `/api/incubator_arrangement/`,
          data: {
            ...data
          }
        });
      },

      fetchIncubatorList: async () => {
        try {
          const payload = await ApiProxy.get({
            secure: true,
            path: `/api/incubator/`
          });

          dispatch({ type: Actions.SET_INCUBATOR_LIST, payload });
        } catch (ex) {
          console.warn(ex);
        }
      },

      fetchIncubatorTypeList: async () => {
        try {
          const payload = await ApiProxy.get({
            secure: true,
            path: `/api/incubator_type/`
          });

          dispatch({ type: Actions.SET_INCUBATOR_TYPE_LIST, payload });
        } catch (ex) {
          console.warn(ex);
        }
      },

      fetchIncubatorByBabyId: id => {
        return ApiProxy.get({
          secure: true,
          path: `/api/incubator_arrangement/?baby=${id}`
        });
      },

      deleteBabyLivingRecord: id => {
        return ApiProxy.delete({
          secure: true,
          path: `/api/incubator_arrangement/${id}`
        });
      },

      createSparePartItem: (name, unit_price, total) => {
        return ApiProxy.post({
          secure: true,
          path: `/api/spare_part/`,
          data: {
            name,
            unit_price,
            total
          }
        });
      },

      fetchSparePartList: async () => {
        try {
          const payload = await ApiProxy.get({
            secure: true,
            path: `/api/spare_part/`
          });

          dispatch({ type: Actions.SET_SPARE_PART_LIST, payload });
        } catch (ex) {
          console.warn(ex);
        }
      },

      fetchSparePartId: id => {
        return ApiProxy.get({
          secure: true,
          path: `/api/spare_part/${id}/`
        });
      },

      fetchSparePartRecord: id => {
        return ApiProxy.get({
          secure: true,
          path: `/api/spare_part_record/?part=${id}`
        });
      },

      createSparePartRecord: (id, quantity, operation, reason) => {
        return ApiProxy.post({
          secure: true,
          path: `/api/spare_part_record/`,
          data: {
            part: id,
            quantity,
            operation,
            reason
          }
        });
      },

      searchMomLivingRecordsByDate: ({ from_date, duration }) => {
        return ApiProxy.get({
          secure: true,
          path: `/api/mom_living_record/?from_date=${from_date}&duration=${duration}`
        });
      },

      searchMomLivingRecords: ({ mother }) => {
        return ApiProxy.get({
          secure: true,
          path: `/api/mom_living_record/?mother=${mother}`
        });
      },

      createMomLivingRecord: data => {
        return ApiProxy.post({
          secure: true,
          path: `/api/mom_living_record/`,
          data: {
            ..._idFlatten(data)
          }
        });
      },

      updateMomLivingRecord: ({ id, ...data }) => {
        return ApiProxy.put({
          secure: true,
          path: `/api/mom_living_record/${id}/`,
          data: {
            ..._idFlatten(data)
          }
        });
      },

      deleteMomLivingRecord: id => {
        return ApiProxy.delete({
          secure: true,
          path: `/api/mom_living_record/${id}/`
        });
      },

      fetchRoomTypeList: async () => {
        try {
          const payload = await ApiProxy.get({
            secure: true,
            path: `/api/room_type/`
          });

          dispatch({ type: Actions.SET_ROOM_TYPE_LIST, payload });
        } catch (ex) {
          console.warn(ex);
        }
      },

      fetchRoomList: async () => {
        try {
          const payload = await ApiProxy.get({
            secure: true,
            path: `/api/room/`
          });

          dispatch({ type: Actions.SET_ROOM_LIST, payload });
        } catch (ex) {
          console.warn(ex);
        }
      },

      fetchMotherList: () => {
        return ApiProxy.get({
          secure: true,
          path: `/api/mother/`
        });
      },

      fetchMother: id => {
        return ApiProxy.get({
          secure: true,
          path: `/api/mother/${id}/`
        });
      },

      fetchBabyByDate: ({ from_date, duration }) => {
        return ApiProxy.get({
          secure: true,
          path: `/api/baby/?from_date=${from_date}&duration=${duration}`
        });
      },

      createBaby: data => {
        return ApiProxy.post({
          secure: true,
          path: `/api/baby/`,
          data: {
            ...data
          }
        });
      },

      updateBaby: ({ id, ...data }) => {
        return ApiProxy.put({
          secure: true,
          path: `/api/baby/${id}/`,
          data: {
            ...data
          }
        }).then(babyData => {
          dispatch({
            type: Actions.SET_BABY_DASHBOARD_STATE,
            payload: { selectedBaby: babyData }
          });
          return babyData;
        });
      },

      deleteBaby: id => {
        return ApiProxy.delete({
          secure: true,
          path: `/api/baby/${id}/`
        });
      },

      updateMealInfo: ({ id, ...data }) => {
        return ApiProxy.put({
          secure: true,
          path: `/api/mom_meal_info/${id}/`,
          data: {
            ..._idFlatten(data)
          }
        });
      },

      createMealInfo: ({ contract, ...data }) => {
        return ApiProxy.post({
          secure: true,
          path: `/api/mom_meal_info/`,
          data: {
            contract,
            ...data
          }
        });
      },

      deleteMealInfo: id => {
        return ApiProxy.delete({
          secure: true,
          path: `/api/mom_meal_info/${id}/`
        });
      },

      searchContracts: ({ mother }) => {
        return ApiProxy.get({
          secure: true,
          path: `/api/contract/?mother=${mother}`
        });
      },

      createContract: data => {
        return ApiProxy.post({
          secure: true,
          path: `/api/contract/`,
          data: {
            ...data
          }
        });
      },

      fetchPrecheckinEvalList: ({ mother }) => {
        return ApiProxy.get({
          secure: true,
          path: `/api/mom_pre_checkin_eval/?mother=${mother}`
        });
      },

      createPrecheckinEval: data => {
        return ApiProxy.post({
          secure: true,
          path: `/api/mom_pre_checkin_eval/`,
          data: {
            ...data
          }
        });
      },

      updatePrecheckinEval: ({ id, ...data }) => {
        return ApiProxy.put({
          secure: true,
          path: `/api/mom_pre_checkin_evel/${id}/`,
          data: {
            ..._idFlatten(data)
          }
        });
      },

      deletePrecheckinEval: id => {
        return ApiProxy.delete({
          secure: true,
          path: `/api/mom_pre_checkin_evel/${id}/`
        });
      },

      fetchContractList: () => {
        return ApiProxy.get({
          secure: true,
          path: `/api/contract/`
        });
      },

      updateContract: ({ id, ...data }) => {
        return ApiProxy.put({
          secure: true,
          path: `/api/contract/${id}/`,
          data: {
            ..._idFlatten(data)
          }
        });
      },

      deleteContract: id => {
        return ApiProxy.delete({
          secure: true,
          path: `/api/contract/${id}/`
        });
      },

      searchVisits: ({ mother }) => {
        return ApiProxy.get({
          secure: true,
          path: `/api/visit_record/?mother=${mother}`
        });
      },

      fetchVisit: data => {
        return ApiProxy.get({
          secure: true,
          path: `/api/visit_record/`
        });
      },

      fetchVisitByDate: ({ from_date, duration, field }) => {
        return ApiProxy.get({
          secure: true,
          path: `/api/visit_record/?from_date=${from_date}&duration=${duration}&field=${field}`
        });
      },

      createVisit: data => {
        return ApiProxy.post({
          secure: true,
          path: `/api/visit_record/`,
          data: {
            ..._idFlatten(data)
          }
        });
      },

      updateVisit: ({ id, ...data }) => {
        return ApiProxy.put({
          secure: true,
          path: `/api/visit_record/${id}/`,
          data: {
            ..._idFlatten(data)
          }
        });
      },

      deleteVisit: id => {
        return ApiProxy.delete({
          secure: true,
          path: `/api/visit_record/${id}/`
        });
      },

      fetchAssignment: () => {
        return ApiProxy.get({
          secure: true,
          path: `/api/assignment/`
        });
      },

      createAssignment: data => {
        return ApiProxy.post({
          secure: true,
          path: `/api/assignment/`,
          data: {
            ...data
          }
        });
      },

      updateAssignment: ({ id, ...data }) => {
        return ApiProxy.put({
          secure: true,
          path: `/api/assignment/${id}`,
          data: {
            ...data
          }
        });
      },

      deleteAssignment: id => {
        return ApiProxy.delete({
          secure: true,
          path: `/api/assignment/${id}`
        });
      },

      searchMothers: ({ name, phone }) => {
        let nameQuery = (name && `&name=${name}`) || "";
        let phoneQuery = (phone && `&phone=${phone}`) || "";

        return ApiProxy.get({
          secure: true,
          path: `/api/mother/?limit=100${nameQuery}${phoneQuery}`
        });
      },

      createMother: ({ name, phone }) => {
        if (!name || !phone) {
          return Promise.reject();
        }

        return ApiProxy.post({
          secure: true,
          path: `/api/mother/`,
          data: {
            name,
            phone
          }
        });
      },

      updateMother: ({ id, phone, name, ...data }) => {
        if (!name || !phone) {
          return Promise.reject();
        }

        return ApiProxy.put({
          secure: true,
          path: `/api/mother/${id}/`,
          data: {
            name,
            phone,
            ...data
          }
        }).then(momData => {
          dispatch({
            type: Actions.SET_MOM_DASHBOARD_STATE,
            payload: { selectedMother: momData }
          });
          return momData;
        });
      },

      setMomDashboardState: (newState = {}) => {
        dispatch({ type: Actions.SET_MOM_DASHBOARD_STATE, payload: newState });
      },

      setBabyDashboardState: (newState = {}) => {
        dispatch({ type: Actions.SET_BABY_DASHBOARD_STATE, payload: newState });
      },

      setTopNavState: (newState = "") => {
        dispatch({ type: Actions.SET_TOP_NAV_STATE, payload: newState });
      }
    };
  }

  const selectors = () => ({
    getUsers: state => state[name].users,
    getRoomTypes: state => state[name].roomTypes,
    getRooms: state => state[name].rooms,
    getIncubatorTypes: state => state[name].incubatorTypes,
    getIncubators: state => state[name].incubators,
    getSpareParts: state => state[name].spareParts,
    getTopNavState: state => state[name].topNavState,
    getMomDashboardState: state => state[name].momDashboardState,
    getBabyDashboardState: state => state[name].babyDashboardState
  });

  return {
    name,
    reducer,
    actionCreator,
    selectors
  };
}
