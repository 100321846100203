import categories from "../../content/categories.json";
import products from "../../content/products.json";

export default function CatalogService(config = {}) {
  const name = "catalog";

  function reducer(state = undefined, { actions, payload }) {
    // const initialState = {
    //     items: [],
    //     itemsMap: {},
    //     categories: {},
    // };
    let dataMap = {};
    products.forEach(p => (dataMap[p.id] = p));
    return {
      items: products,
      itemsMap: dataMap,
      categories: {
        /*
                 construct tree structure
                 categories data is [A, B, C]
                 ==>  All
                     / | \
                     A  B  C
                 */
        id: 0,
        display_name: "首頁",
        display_name_en: "Home",
        display_name_jp: "Home",
        display_name_kr: "Home",
        display_name_cn: "首页",
        name: "home",
        children: categories.map(c => ({
          ...c,
          parent: { id: 0, name: "all" }
        }))
      }
    };
  }

  function actionCreator(store) {
    return {
      test: string => {
        console.log("test catalog AC..." + string);
      }
    };
  }

  const selectors = state => ({
    getItemById: id => state[name].itemsMap[id],
    getItemsByCatId: id => {
      let foundCat = findCategory(state[name].categories, id);
      return foundCat
        ? foundCat.items.map(itemId => state[name].itemsMap[itemId])
        : [];
    },
    getParentCategoriesBacktraceById: id => {
      return getParentCategoriesBacktrace(state[name].categories, id);
    },
    getSiblingCategoriesById: id => {
      let currentCat = findCategory(state[name].categories, id);
      if (!currentCat || id === 0) {
        return [];
      }
      let parent = findCategory(state[name].categories, currentCat.parent.id);
      return parent.children;
    }
  });

  function findCategory(cat, id) {
    if (cat.id === id) {
      return cat;
    } else if (cat.children.length !== 0) {
      for (let i = 0; i < cat.children.length; i++) {
        let result = null;
        result = findCategory(cat.children[i], id);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  function getParentCategoriesBacktrace(catTree, categoryId) {
    let category = findCategory(catTree, categoryId);
    if (!category) {
      return [];
    }

    let backtrace = [],
      currentCate = category;
    while (currentCate) {
      backtrace.push(currentCate);
      if (!currentCate.parent) {
        break;
      }
      currentCate = findCategory(catTree, currentCate.parent.id);
    }
    return backtrace.reverse();
  }

  return {
    name,
    reducer,
    actionCreator,
    selectors
  };
}
