import React from "react";
import styled from "styled-components";
import * as Css from "../../../src/Components/Css";

export const Absolute = styled.div`
  ${Css.Absolute} ${props => props.css};
`;

export const Figure = styled.figure`
  ${Css.Background} ${props => props.css};
`;

export const Center = styled.div`
  ${Css.Center} ${props => props.css};
`;

export const Container = styled.div`
  ${props => props.css || ""};
`;

export const TabContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #e8e8e8;
  overflow: auto;

  & > .content {
    flex: 1;
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0 2px 2px 0 rgba(154, 154, 154, 0.5);
  }

  ${props => props.css || ""};
`;

export const TabItem = styled.button`
  border-top: 0px;
  border-left: 0px;
  border-bottom: 0px;
  border-right: 0px;
  border-top: ${props => props.selected && "5px solid #88ADD8"};
  margin: 0px;
  width: 120px;
  height: 50px;
  background-color: ${props => (props.selected ? "white" : "#F0F0F0")};
  font-size: 14px;
  color: #777777;

  :focus {
    outline: 0;
  }
`;

export const DialogWrapper = styled.div`
  padding: 30px;
  position: relative;
  max-height: 85vh;
  ${props => props.css};
`;

export const DialogTitle = styled.h3`
  display: flex;
  margin: 0px 15px;
  padding-bottom: 10px;
  color: #777777;
  ${props => props.css};
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 65vh;
  overflow: auto;
  ${props => props.css};
`;

export const MaxWidth = styled.div`
  max-width: ${props => props.width}px;
  margin: ${props => props.margin || "0 auto"};
  ${props => props.css};
`;

export const Row = styled.div`
  display: flex;
  align-items: ${props => props.align || "stretch"};
  justify-content: ${props => props.justify || "flex-start"};
  flex-wrap: ${props => (props.wrap ? "wrap" : "nowrap")};
  ${props => props.css};
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align || "stretch"};
  justify-content: ${props => props.justify || "flex-start"};
  ${props => props.css};
`;

export const Button = styled.button`
  display: inline-block;
  border: none;
  padding: 8px 12px;
  margin-left: 10px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: ${props => props.color || "#4485D1"};
  color: ${props => props.textColor || "#fff"};
  font-size: ${props => props.fontSize || "14px"};

  & :hover {
    font-weight: 900;
    background: ${props => props.hoverColor || props.color || "#478fe4"};
  }

  & :focus {
    background: ${props => props.focusColor || props.color || "#478fe4"};
    outline: none;
  }

  & :active {
    transform: scale(0.88);
  }

  & :disabled {
    background: ${props => props.disableColor || "#ccc"};
    color: ${props => props.disableTextColor || "#666"};
  }

  &.white-button {
    background: #ffffff;
    color: #777777;
    border: 1px solid #dfdfdf;
  }

  ${props => props.css || ""};
`;

export const Clickable = styled.div`
  cursor: pointer;
  transition: transform 0.2s;

  & :hover {
    transform: scale(0.9);
  }

  ${props => props.css || ""};
`;

export const FlatButton = styled.button`
  display: inline-block;
  border: none;
  padding: 8px 12px;
  margin-left: 10px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  transition: font-weight 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: ${props => props.background || "transparent"};
  color: ${props => props.color || "black"};
  font-size: ${props => props.fontSize || "16px"};

  & :hover {
    transform: scale(1.05);
    color: ${props => props.color || "black"};
  }

  & :focus {
    color: ${props => props.color || "black"};
    outline: none;
  }

  & :active {
    transform: scale(0.88);
  }

  & :disabled {
    color: ${props => props.color || "#ccc"};
  }

  ${props => props.css || ""};
`;

export const Fields = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: ${props => (props.lessTable ? "525px" : "920px")};
  height: 40px;

  & > .field {
    display: flex;
    align-items: center;
    width: 100px;
    padding: 10px 0px 10px 10px;
    font-weight: 500;
    font-size: 14px;
    color: #777777;
  }

  & .date-field {
    display: flex;
    align-items: center;
    min-width: 100px;
    padding: 10px 0px 10px 10px;
    font-weight: 500;
    font-size: 14px;
    color: #777777;
  }

  & .big-field {
    display: flex;
    align-items: center;
    padding: 10px 0px 10px 10px;
    font-weight: 500;
    font-size: 14px;
    color: #777777;
    width: 200px;
  }

  & .small-field {
    width: 70px;
  }

  & .field-padding {
    flex: 1 0 0px;
  }

  ${props => props.css || ""};
`;

export const HintMessage = styled.div`
  padding-left: 10px;
  letter-spacing: 0.2px;
  font-weight: 200;
  font-size: 14px;
  color: #777777;
`;

export const Table = styled.div`
  position: relative;
  width: calc(100vw - 310px);
  overflow: auto;

  @media screen and (max-width: 770px) {
    width: calc(100vw - 230px);
  }

  ${props => props.css};
`;
export const TableContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;

  & .date-item {
    min-width: 100px;
    padding: 10px 0px 10px 10px;
  }

  & .row-item {
    width: 100px;
    padding: 10px 0px 10px 10px;
  }

  & .big-item {
    width: 200px;
    padding: 10px 0px 10px 10px;
  }

  & .small-item {
    width: 70px;
  }

  & .row-item-padding {
    flex: 1 0 0px;
  }

  & > .row-even {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 14px;
    min-height: 40px;
    min-width: ${props => (props.lessTable ? "525px" : "920px")};
    color: #777777;
    background-color: #f3f3f3;
  }

  & > .row-odd {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 14px;
    min-height: 40px;
    min-width: ${props => (props.lessTable ? "525px" : "920px")};
    color: #777777;
    background-color: #ffffff;
  }

  ${props => props.css || ""};
`;

export const MultipleChoicesFormField = styled.div`
  margin: 15px;
  flex-direction: ${props => (props.stacked ? "column" : "row")};
  & label {
    color: #777777;
    font-size: 14px;
    margin-bottom: ${props => props.stacked && "8px"};
  }
  & .choice-of-multiple {
    padding-right: 20px;
    & > input {
      color: #777777;
      border: 1px solid #e9eff4;
      margin: 3px;
      border-radius: 4px;
      padding: 10px;
    }
  }
`;

export const FormFieldsBlock = styled.div`
  background-color: #f7f7f7;
  border-left: 5px solid #88add8;
  display: flex;
  flex-wrap: ${props => (props.wrap ? "wrap" : "nowrap")};
  width: 100%;
  margin: 10px 0px;

  & > label {
    margin: 15px;
    color: #777777;
    font-size: 14px;
  }
`;

export const FormField = styled.div`
  margin: 15px;
  display: flex;
  align-items: ${props => !props.stacked && "center"};
  flex-direction: ${props => (props.stacked ? "column" : "row")};

  & > label {
    color: #777777;
    font-size: 14px;
    margin-right: ${props => !props.stacked && "20px"};
    margin-bottom: ${props => props.stacked && "8px"};
  }

  & > input {
    color: #777777;
    border: 1px solid #e9eff4;
    background-color: white;
    width: ${props => props.inputWidth || "200px"};
    height: ${props => props.inputHeight || "45px"};
    border-radius: 4px;
    padding: 10px;
  }

  & > input:focus {
    outline: none;
  }

  & > input[type="datetime-local"] {
    width: 200px;
  }

  & > input[type="checkbox"] {
    margin-top: 5px;
    width: ${props => props.checkboxWidth || "20px"};
    height: ${props => props.checkboxHeight || "20px"};
  }

  & > input:disabled {
    color: grey;
  }

  & > select {
    height: 45px;
    border: 1px solid #e9eff4;
    color: #777777;
    background-color: #ffffff;
    width: ${props => props.selectWidth || props.inputWidth || "200px"};
  }

  & > select > option {
    direction: rtl;
  }

  /* checkbox style: checkbox + label */
  & > div {
    padding: 10px;
  }

  & > div > label {
    font-size: 14px;
    color: #777777;
    margin-right: ${props => !props.stacked && "20px"};
  }

  & > div > label > input {
    margin-right: ${props => !props.stacked && "10px"};
  }

  ${props => props.css || ""};
`;

export class Spinner extends React.Component {
  render() {
    return (
      <Spinner.Wrapper>
        <Spinner.Container />
        <Spinner.Image src="/images/pages/momcenter-logo.png" />
      </Spinner.Wrapper>
    );
  }

  static Wrapper = styled.div`
    position: relative;
  `;

  static Container = styled.div`
    border: 10px solid lightblue;
    border-bottom-color: transparent;
    border-radius: 50%;
    width: 80px;
    height: 80px;

    @keyframes infinite-spinner {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    animation: infinite-spinner 0.8s infinite;
  `;

  static Image = styled.img`
    position: absolute;
    top: 8px;
    left: 8px;

    border-radius: 50%;
    padding: 5px;
    background-color: #88add8;
    width: 65px;
    height: 65px;
  `;
}

export const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

export const SearchBar = styled.div`
  margin: 10px 20px 15px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(154, 154, 154, 0.5);
  width: 400px;
  height: 70px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > .title {
    margin-right: 30px;
    border-right: 1px solid #eaeaea;
    padding: 10px;
    max-width: 58px;
    text-align: center;
    font-size: 14px;
    color: #777777;
  }

  & > .searchInput {
    display: flex;
    align-items: center;
    border: 1px solid #e9eff4;
    border-radius: 4px;
    margin-right: 20px;
    padding-left: 10px;
    width: 270px;
    height: 40px;
  }

  & > div > input {
    width: 220px;
    font-size: 14px;
    color: #777777;
    border: 0px;
    padding-left: 10px;

    ::placeholder {
      color: lightgray;
    }

    :focus {
      outline: none;
    }
  }
`;
