import {css} from 'styled-components';

export const Absolute = css`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const Center = css`
  display: flex; 
  align-items: center; 
  justify-content: center;
  flex-direction: column; // normally we want such a case
`;

export const Background = css`
  background-color: ${props => props.color || '#d0d0d0'};
  background-image: url(${props => props.image});
  background-size: ${props => props.size || 'cover'};
  background-repeat: ${props => props.repeat || 'no-repeat'};
  background-position: ${props => props.position || 'center'};
`;
